import { computed, ref } from '@vue/composition-api'

const NO_STATEMENT_GROUP_TEXT_FILTER = ''

const statementGroupSearchQueryState = ref(NO_STATEMENT_GROUP_TEXT_FILTER)

export const changeStatementGroupSearchQuery = searchQuery => {
	statementGroupSearchQueryState.value = searchQuery
}

export const statementGroupSearchQuery = computed({
	get () {
		return statementGroupSearchQueryState.value
		// your getter
	},
	set (value) {
		changeStatementGroupSearchQuery(value)
		// your setter
	}
})
