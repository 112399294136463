<template>
	<StatementGroupsLayout>
		<template #content>
			<FilterBar
				:search-query.sync="statementGroupSearchQuery"
				search-query-label="Statement Group Name"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				@persistSearchQuery="changeStatementGroupSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
			>
				<template #action>
					<PrimaryActionButton
						v-if="!readOnlyPermissions"
						@click="onCreateClick"
					>
						<v-icon
							left
							dark
						>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					{{ 'Statement Groups' | useLabels }}
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredStatementGroups"
						sort-by="statementGroupID"
						sort-desc
						@click:row="onEditStatementGroup"
					>
						<template #item.statementGroupName="{ item }">
							{{ getTruncatedText(item.statementGroupName, 50) }}
						</template>
						<template #item.channelName="{ item }">
							{{ getTruncatedText(item.channelName, 50) }}
						</template>
						<template #item.action="{ item }">
							<v-icon
								small
								class="mr-2"
								@click="onEditStatementGroup(item)"
							>
								{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
							</v-icon>
							<v-icon
								v-if="!readOnlyPermissions"
								small
								@click.stop.prevent="onRemoveStatementGroup(item)"
							>
								mdi-trash-can
							</v-icon>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="statementGroupToBeRemoved"
				:entity-name="statementGroupToBeRemoved.statementGroupName"
				entity-type="Statement Group"
				@close="statementGroupToBeRemoved = null"
				@delete="deleteStatementGroup"
			/>
		</template>
	</StatementGroupsLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import StatementGroupsLayout from './statement-groups-layout.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import { changeStatementGroupSearchQuery,	statementGroupSearchQuery } from '../../../../../shared/state/statement-groups.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { MANAGE_STATEMENT_GROUP } from '../../../router/route-names.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { deleteStatementGroup, getStatementGroups } from '../../../../../shared/utils/api/statement-groups.js'
import { STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'
export default {
	components: { ConfirmDeleteModal, DataTable, SectionCard, PrimaryActionButton, FilterBar, StatementGroupsLayout },
	setup () {
		return {
			getTruncatedText,
			changeStatementGroupSearchQuery,
			statementGroupSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			statementGroupToBeRemoved: null,
			searchQuery: null,
			selectedBrandId: null,
			statementGroups: []
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		...mapGetters('auth', ['userHasUniversalBrand']),
		tableHeaders () {
			return [
				{ value: 'statementGroupID', text: 'ID', width: '10%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '20%' },
				{ value: 'statementGroupName', text: useLabels('Statement Group Name'), width: '20%' },
				{ value: 'channelName', text: useLabels('Channel Name'), width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		readOnlyPermissions () {
			return !this.checkPermission(STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS)
		},
		filteredStatementGroups () {
			return this.statementGroups.filter(({ statementGroupName, brandId }) => {
				let check = true
				if (statementGroupSearchQuery.value) check = statementGroupName.toLowerCase().includes(statementGroupSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			}).sort(function (a, b) {
				const textA = a.channelName.toUpperCase()
				const textB = b.channelName.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
		}
	},
	created () {
		this.loadStatementGroups()
	},
	methods: {
		async loadStatementGroups () {
			const { data } = await getStatementGroups()
			this.statementGroups = data.statementGroups
		},
		async deleteStatementGroup () {
			await deleteStatementGroup(this.statementGroupToBeRemoved.statementGroupID)
			this.statementGroupToBeRemoved = null
			showSnackbar(useLabels('This statement group has been removed'))
			await this.loadStatementGroups()
		},
		onCreateClick () {
			this.$router.push({
				name: MANAGE_STATEMENT_GROUP
			})
		},
		onEditStatementGroup (statementGroupToEdit) {
			this.$router.push({
				name: MANAGE_STATEMENT_GROUP,
				params: {
					statementGroupToEdit
				}
			})
		},
		onRemoveStatementGroup (statementGroupToBeRemoved) {
			this.statementGroupToBeRemoved = statementGroupToBeRemoved
		}
	}
}
</script>
