import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getStatementGroups = () => configApi.get('/api/Channels').then(({ data }) => ({
	data: {
		statementGroups: data.channels.map(channel => {
			return channel.statementGroups.map(statementGroup => ({
				...statementGroup,
				channelName: channel.channelName,
				channelId: channel.channelID,
				brandId: channel.brandID,
				brandName: channel.brandName,
				createdDate: format(new Date(statementGroup.createdDate), DATE_FORMAT)
			}))
		}).flat()
	}
}))

export const createStatementGroup = statementGroup => configApi.post('/api/Statements/StatementGroup', statementGroup)

export const updateStatementGroup = statementGroup => configApi.put('/api/Statements/StatementGroup', statementGroup)

export const manageAssignedStatements = statements => configApi.post('/api/StatementGroups/ManageAssignedStatements', statements)

export const deleteStatementGroup = statementGroupID => {
	return configApi.delete(`/api/StatementGroups/${statementGroupID}`)
}
